@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-open {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
